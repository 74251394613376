<template>
  <div class="cardPage">
      <div class="card-box">
        <div class="card" v-for="item in tableData" :key="item.id">
          <div class="head"></div>
          <div class="val-boxs">
            <div class="row-box">
              <p class="title">客户名称</p>
              <p class="val" :title="item.customerName">{{item.customerName}}</p>
            </div>
            <div class="row-box2">
              <div class="row">
                <p class="title">地区</p>
                <p class="val" :title="`${item.provinceName} — ${item.cityName}`">{{item.provinceName}} — {{item.cityName}}</p>
              </div>
              <div class="row">
                <p class="title">来源方式</p>
                <p class="val" :title="setSource(item.source)">{{setSource(item.source)}}</p>
              </div>
            </div>
            <!-- <div class="row-box">
              <p class="title">地区</p>
              <p class="val" :title="`${item.provinceName} — ${item.cityName}`">{{item.provinceName}} — {{item.cityName}}</p>
            </div>
            <div class="row-box">
              <p class="title">来源方式</p>
              <p class="val" :title="setSource(item.source)">{{setSource(item.source)}}</p>
            </div> -->
            <div class="row-box">
              <p class="title">任务类型</p>
              <p class="val" :title="item.assignmentTypeName">{{item.assignmentTypeName}}</p>
            </div>
            <div class="row-box">
              <p class="title">问题描述</p>
              <p class="val" :title="item.description">{{item.description}}</p>
            </div>
            <div class="btn-boxs">
              <span @click="look(item)">查看</span>
              <el-button @click="clickGet(item)" v-if="orderType === 1">领取</el-button>
              <el-button @click="editTask(item)" v-if="orderType === 2 && item.status === 0">编辑</el-button>
              <el-button @click="clickAdd(item)" v-if="orderType === 3 && !item.isTransfer">快速创建任务</el-button>
              <i class="el-icon-delete del-icon" @click="del(item)" v-if="orderType === 2 && item.status === 0"></i>
            </div>
          </div>
        </div>
      </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
    <getTaskDialog v-if="showDialog" :info="myInfo"></getTaskDialog>
    <affirmTaskDialog v-if="showDialog2" :info="myInfo"></affirmTaskDialog>
  </div>
</template>

<script>
import { getOrderList, receiveOrder, getCreatedOrderList, getReceivedOrderList, delOrder, taskTransition } from "@/FackApi/api/caseSource.js"
import getTaskDialog from '@/views/Dialog/getTaskDialog'
import affirmTaskDialog from '@/views/Dialog/affirmTaskDialog'
export default {
  name: "cardPage",
  components: {
    getTaskDialog,
    affirmTaskDialog,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      interPage: 12,
      dataSize: 0,
      orderType: 0, // 当前订单类型 1：案件源 2：发布案件 3：领取案件
      myInfo: {
        customerName: "",
      },
      showDialog: false,
      showDialog2: false,
    }
  },
  beforeDestroy() {
  },
  created() {
  },
  computed: {
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.currentPage = 1;
      this.interPage = 12;
      this.dataSize = 0;
      this.getData();
    },
    async getData() {
      this.orderType = this.$parent.isTabes;
      let params = `${this.currentPage}/${this.interPage}`;
      let filterInfo = this.$parent.filterInfo;
      // params = `${params}?assignmentTypeName=${filterInfo.type}&provinceId=${filterInfo.province}&cityId=${filterInfo.city}&queryString=${filterInfo.keyVal}`;
      if (filterInfo.type) {
        params = `${params}?assignmentTypeName=${filterInfo.type}`;
      }
      if (filterInfo.city) {
        if (filterInfo.type) {
          params = `${params}&provinceId=${filterInfo.province}&cityId=${filterInfo.city}`;
        } else {
          params = `${params}?provinceId=${filterInfo.province}&cityId=${filterInfo.city}`;
        }
      }
      if (filterInfo.keyVal) {
        if (filterInfo.type || filterInfo.city) {
          params = `${params}&queryString=${filterInfo.keyVal}`;
        } else {
          params = `${params}?queryString=${filterInfo.keyVal}`;
        }
      }
      // console.log(params,'参数');
      let res = {};
      if (this.orderType === 1) {
        res = await getOrderList(params);
      } else if (this.orderType === 2) {
        res = await getCreatedOrderList(params);
      } else if (this.orderType === 3) {
        res = await getReceivedOrderList(params);
      }
      // console.log(res,'获取订单列表');
      if (res.code === 0) {
        this.dataSize = res.totalCount;
        this.tableData = res.items;
      }
    },
    // 查看
    look(row) {
      this.$parent.look(row);
    },
    clickGet(row) {
      this.myInfo = row
      this.showDialog = true;
    },
    // 领取
    async getTask(row) {
      let res = await receiveOrder({id: this.myInfo.id});
      if (res.code === 0) {
        this.$message({
          message: '领取成功',
          type: 'success'
        });
        this.init();
      }
    },
    async clickAdd(row) {
      this.myInfo = row;
      let params = {
        id: this.myInfo.id,
        customerName: this.myInfo.customerName,
      }
      let res = await taskTransition(params);
      if (res.code === 0) {
        this.$message({
          message: '创建成功',
          type: 'success'
        });
        this.showDialog2 = false;
        this.getData();
      } else if (res.code === 40000) {
        this.showDialog2 = true;
      }
    },
    // 快速创建项目
    async creation(info) {
      let params = {
        id: this.myInfo.id,
        isConfirm: true,
        isCreateNewCustomer: info.num === 1 ? false:true,
        customerName: info.num === 1 ? '':info.name,
      }
      let res = await taskTransition(params);
      // console.log(res,"快速创建返回");
      if (res.code === 0) {
        this.$message({
          message: '创建成功',
          type: 'success'
        });
        this.showDialog2 = false;
        this.getData();
      }
    },
    // 编辑
    editTask(row) {
      this.$emit('editTask',row);
    },
    // 删除
    del(row) {
      this.$confirm(`请确认是否删除客户【 ${row.customerName} 】案源？`)
        .then((_) => {
          this.delTask(row.id);
        })
        .catch((_) => {});
    },
    async delTask(id) {
      let res = await delOrder({ids: [id]});
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.init();
      }
    },
    handleSizeChange(psize) {
      this.interPage = psize;
      this.getData();
    },
    handleCurrentChange(cpage) {
      this.currentPage = cpage;
      this.getData();
    },
    setSource(num) {
      let str = "";
      if (num === 1) {
        str = "ComDoc平台";
      } else if (num === 1) {
        str = "官方发布";
      } else {
        str = "合作渠道";
      }
      return str;
    },
  }
}
</script>

<style lang="less" scoped>
.cardPage {
  margin-top: 30px;
  .card-box {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .card {
      width: 24%;
      // height: 100px;
      border-radius: 18px;
      border: 1px solid #D7DBDA;
      margin: 0 1% 30px 0;
      overflow: hidden;
      .head {
        height: 30px;
        background: linear-gradient(270deg, #F0E4FD 0%, #E6E4FD 100%);
      }
      .val-boxs {
        padding: 20px;
        box-sizing: border-box;
        .row-box {
          width: 100%;
          margin-bottom: 6px;
          .title {
            font-size: 14px;
            color: #999999;
          }
          .val {
            font-size: 14px;
            color: #333333;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .row-box2 {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 6px;
          padding: 0 14px;
          box-sizing: border-box;
          .row {
            max-width: 50%;
            .title {
              font-size: 14px;
              color: #999999;
            }
            .val {
              font-size: 14px;
              color: #333333;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .btn-boxs {
          display: flex;
          align-items: center;
          justify-content: space-around;
          span {
            font-size: 12px;
            color: #6474C7;
            cursor:pointer;
          }
          .del-icon {
            font-size: 20px;
            font-weight: 600;
            color: red;
            margin-left: 10px;
          }
          .el-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 30px;
            font-size: 12px;
            color: #FFFFFF;
            background: #45CFA0;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .el-pagination {
    align-items: initial;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>

