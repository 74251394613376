<template>
  <div class="tablePage">
      <div class="table-container">
        <el-table :data="tableData" ref="table" style="width: 100%">
          <template v-for="(item, index) in headData">
            <el-table-column
              :align="item.align"
              v-if="item.label === '序号'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
               width="120px"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '地区'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="scope">
                {{ scope.row.provinceName }} — {{scope.row.cityName}}
              </template>
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '来源方式'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.source === 1">ComDoc平台</span>
                <span v-if="scope.row.source === 2">官方发布</span>
                <span v-if="scope.row.source === 3">合作渠道</span>
              </template>
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '问题描述'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              show-overflow-tooltip
              width="300px"
            >
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '领取状态' && orderType === 2"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status === 0">未被领取</span>
                <span v-else>已被领取</span>
              </template>
            </el-table-column>
              <!-- fixed="right" -->
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '操作'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              width="220px"
            >
              <template slot-scope="scope">
                <div class="btn-box">
                  <div>
                    <img class="img1" src="@/assets/images/ywc/btn-icon-1.png" alt="" @click="look(scope.row)">
                    <span class="span1" @click="look(scope.row)">查看</span>
                  </div>
                  <div v-if="orderType === 1">
                    <img class="img2" src="@/assets/images/ywc/btn-icon-2.png" alt="" @click="clickGet(scope.row)">
                    <span class="span2" @click="clickGet(scope.row)">领取</span>
                  </div>
                  <div v-if="orderType === 2">
                    <template v-if="scope.row.status === 0">
                      <img class="img2" src="@/assets/images/ywc/btn-icon-3.png" alt="" @click="editTask(scope.row)">
                      <span class="span3" @click="editTask(scope.row)">编辑</span>
                      <i class="el-icon-delete del-icon" @click="del(scope.row)"></i>
                    </template>
                  </div>
                  <div v-if="orderType === 3 && !scope.row.isTransfer">
                    <img class="img2" src="@/assets/images/ywc/btn-icon-2.png" alt="" @click="clickAdd(scope.row)">
                    <span class="span2" @click="clickAdd(scope.row)">快速创建项目</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :align="item.align"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            ></el-table-column>
          </template>
        </el-table>
      </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
    <getTaskDialog v-if="showDialog" :info="myInfo"></getTaskDialog>
    <affirmTaskDialog v-if="showDialog2" :info="myInfo"></affirmTaskDialog>
  </div>
</template>

<script>
import { getOrderList, receiveOrder, getCreatedOrderList, getReceivedOrderList, delOrder, taskTransition } from "@/FackApi/api/caseSource.js"
import getTaskDialog from '@/views/Dialog/getTaskDialog'
import affirmTaskDialog from '@/views/Dialog/affirmTaskDialog'
export default {
  name: "tablePage",
  components: {
    getTaskDialog,
    affirmTaskDialog,
  },
  data() {
    return {
      tableData: [],
      headData: [
        { align: "center", prop: "序号", label: "序号", width: "100px" },
        { align: "center", prop: "customerName", label: "客户名称" },
        { align: "center", prop: "地区", label: "地区" },
        { align: "center", prop: "source", label: "来源方式" },
        { align: "center", prop: "assignmentTypeName", label: "任务类型" },
        { align: "center", prop: "description", label: "问题描述" },
        { align: "center", prop: "操作", label: "操作" },
      ],
      currentPage: 1,
      interPage: 15,
      dataSize: 0,
      arrTableLength: {},
      orderType: 0, // 当前订单类型 1：案件源 2：发布案件 3：领取案件
      myInfo: {
        customerName: "",
      },
      showDialog: false,
      showDialog2: false,
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getTableCellWidth);
  },
  created() {
  },
  mounted() {
    window.addEventListener("resize", this.getTableCellWidth);
    // this.init();
  },
  methods: {
    init() {
      this.currentPage = 1;
      this.interPage = 15;
      this.dataSize = 0;
      this.getData();
    },
    async getData() {
      this.orderType = this.$parent.isTabes;
      // console.log(this.orderType,'this.orderType');
      // console.log(this.$parent.filterInfo);
      let params = `${this.currentPage}/${this.interPage}`;
      let filterInfo = this.$parent.filterInfo;
      // params = `${params}?assignmentTypeName=${filterInfo.type}&provinceId=${filterInfo.province}&cityId=${filterInfo.city}&queryString=${filterInfo.keyVal}`;
      if (filterInfo.type) {
        params = `${params}?assignmentTypeName=${filterInfo.type}`;
      }
      if (filterInfo.city) {
        if (filterInfo.type) {
          params = `${params}&provinceId=${filterInfo.province}&cityId=${filterInfo.city}`;
        } else {
          params = `${params}?provinceId=${filterInfo.province}&cityId=${filterInfo.city}`;
        }
      }
      if (filterInfo.keyVal) {
        if (filterInfo.type || filterInfo.city) {
          params = `${params}&queryString=${filterInfo.keyVal}`;
        } else {
          params = `${params}?queryString=${filterInfo.keyVal}`;
        }
      }
      // console.log(params,'参数');
      let res = {};
      // console.log(this.orderType);
      if (this.orderType === 1) {
        if (this.headData[6].label === "领取状态") this.headData.splice(6,1);
        res = await getOrderList(params);
      } else if (this.orderType === 2) {
        this.headData = [
          { align: "center", prop: "序号", label: "序号", width: "100px" },
          { align: "center", prop: "customerName", label: "客户名称" },
          { align: "center", prop: "地区", label: "地区" },
          { align: "center", prop: "source", label: "来源方式" },
          { align: "center", prop: "assignmentTypeName", label: "任务类型" },
          { align: "center", prop: "description", label: "问题描述" },
          {align: "center", prop: "status", label: "领取状态"},
          { align: "center", prop: "操作", label: "操作" },
        ]
        res = await getCreatedOrderList(params);
      } else if (this.orderType === 3) {
        if (this.headData[6].label === "领取状态") this.headData.splice(6,1);
        res = await getReceivedOrderList(params);
      }
      // let res = await getOrderList(params);
      // console.log(res,'获取订单列表');
      if (res.code === 0) {
        this.dataSize = res.totalCount;
        this.tableData = res.items;
        this.getTableCellWidth();
        this.$nextTick(()=> {
          this.$refs.table.doLayout();
        })
      }
    },
    // 查看
    look(row) {
      this.$parent.look(row);
    },
    clickGet(row) {
      this.myInfo = row
      this.showDialog = true;
    },
    // 领取
    async getTask() {
      let res = await receiveOrder({id: this.myInfo.id});
      if (res.code === 0) {
        this.$message({
          message: '领取成功',
          type: 'success'
        });
        this.showDialog = false;
        this.getData();
      }
    },
    async clickAdd(row) {
      this.myInfo = row;
      let params = {
        id: this.myInfo.id,
        // isConfirm: true,
        // isCreateNewCustomer: true,
        customerName: this.myInfo.customerName,
      }
      let res = await taskTransition(params);
      // console.log(res,"快速创建返回");
      if (res.code === 0) {
        this.$message({
          message: '创建成功',
          type: 'success'
        });
        this.showDialog2 = false;
        this.getData();
      } else if (res.code === 40000) {
        this.showDialog2 = true;
      }
    },
    // 快速创建项目
    async creation(info) {
      let params = {
        id: this.myInfo.id,
        isConfirm: true,
        isCreateNewCustomer: info.num === 1 ? false:true,
        customerName: info.num === 1 ? '':info.name,
      }
      let res = await taskTransition(params);
      // console.log(res,"快速创建返回");
      if (res.code === 0) {
        this.$message({
          message: '创建成功',
          type: 'success'
        });
        this.showDialog2 = false;
        this.getData();
      }
    },
    // 删除
    del(row) {
      this.$confirm(`请确认是否删除客户【 ${row.customerName} 】案源？`)
        .then((_) => {
          this.delTask(row.id);
        })
        .catch((_) => {});
    },
    async delTask(id) {
      let res = await delOrder({ids: [id]});
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.init();
      }
    },
    handleSizeChange(psize) {
      this.interPage = psize;
      this.getData();
    },
    handleCurrentChange(cpage) {
      this.currentPage = cpage;
      this.getData();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
      // console.log(this.arrTableLength,'this.arrTableLength');
    },
    // 编辑任务
    editTask(row) {
      // console.log(row);
      this.$emit('editTask',row);
    },
  }
}
</script>

<style lang="less" scoped>
.tablePage {
  margin-top: 30px;
  .table-container {
    flex: 1;
    /deep/ .el-table {
      border-radius: 18px 18px 0 0;
      .el-table__header-wrapper {
        .el-table__header {
          // width: 100%;
          .has-gutter {
            th {
              font-size: 14px;
              font-weight: bold;
              color: #6474C7;
              padding: 12px 16px;
              background: #EAF5FF;
            }
          }
        }
      }
      .el-table__body-wrapper {
        min-height: 470px;
        .el-table__body {
          td {
            font-size: 14px;
            color: #333;
            padding: 12px 16px;
            .cell {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              .btn-box {
                display: flex;
                justify-content: space-around;
                div {
                  display: flex;
                  align-items: center;
                  cursor:pointer;
                  .img1 {
                    width: 20px;
                    height: 14px;
                    margin-right: 6px;
                  }
                  .img2 {
                    width: 17px;
                    height: 17px;
                    margin-right: 6px;
                  }
                  span {
                    font-size: 14px;
                  }
                  .span1 {
                    color: #6474C7;
                    margin-right: 10px;
                  }
                  .span2 {
                    color: #45CFA0;
                  }
                  .span3 {
                    color: #FCC647;
                  }
                  .del-icon {
                    font-size: 20px;
                    font-weight: 600;
                    color: red;
                    margin-left: 10px;
                  }
                  .span4 {
                    color: #333;
                    cursor:default;
                  }
                }
                // p {
                //   font-size: 14px;
                //   color: #6474C7;
                //   cursor:pointer;
                // }
                // .btn-p {
                //   color: #FCC647;
                // }
              }
            }
          }
          // .in-column {
          //   .cell {
          //     .btn-box {
          //       display: flex;
          //       justify-content: space-between;
          //       div {
                  
          //       }
          //       // p {
          //       //   font-size: 14px;
          //       //   color: #6474C7;
          //       //   cursor:pointer;
          //       // }
          //       // .btn-p {
          //       //   color: #FCC647;
          //       // }
          //     }
          //   }
          // }
        }
      }
      .el-table__fixed-right {
        thead {
          .is-center {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%) !important;
          }
        }
          .in-column {
            .cell {
              .btn-box {
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 14px;
                  color: #6474C7;
                  cursor:pointer;
                }
                .btn-p {
                  color: #FCC647;
                }
              }
            }
          }
      }
    }
  }
  .el-pagination {
    align-items: initial;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>

