<template>
  <div class="get-task-dialog">
    <el-dialog
      title="领取任务"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="660px"
    >
      <div class="content-box">
        <div class="head">
          <span class="left-span">客户信息</span>
          <span class="right-span">* 请联系客户沟通任务详情</span>
        </div>
        <div class="card-box">
          <div class="max-row">
            <span class="label">客户名称</span>
            <span class="val" :title="info.customerName">{{info.customerName}}</span>
          </div>
          <div class="row-box">
            <div class="left">
              <span class="label">企业类型</span>
              <span class="val">{{info.customerType === 1? '个人':'企业'}}</span>
            </div>
            <div class="right">
              <span class="label">联系人</span>
              <span class="val">{{info.customerContact}}</span>
            </div>
          </div>
          <div class="row-box">
            <div class="left">
              <span class="label">所在城市</span>
              <span class="val" :title="`${info.provinceName} — ${info.cityName}`">{{info.provinceName}} — {{info.cityName}}</span>
            </div>
            <div class="right">
              <span class="label">联系方式</span>
              <span class="val">{{info.customerContactTel}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="receive">已与客户确认领取</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "getTaskDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      myInfo: null,
    };
  },
  created() {
  },
  mounted() {
    // console.log(this.info);
  },
  methods: {
    receive() {
      this.$parent.getTask();
    },
    cancel() {
      this.$parent.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.get-task-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            white-space: nowrap;
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .content-box {
          margin: 0 0 10px 0;
          .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            .left-span {
              font-size: 14px;
              font-weight: 600;
              color: #333333;
            }
            .right-span {
              font-size: 12px;
              color: #909399;
            }
          }
          .card-box {
            padding: 10px;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #D7DBDA;
              .label {
                display: block;
                min-width: 80px;
                font-size: 14px;
                color: #909399;
                margin-right: 0;
              }
              .val {
                width: calc(100%-80px);
                font-size: 14px;
                color: #333333;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            .max-row {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
            }
            .row-box {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              .left,
              .right {
                display: flex;
                width: 45%;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            width: 200px;
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
