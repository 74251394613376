<template>
  <div class="highSeas">
    <!-- <p class="all-crumbs">业务池 > 业务公海</p> -->
    <div class="tabs-box" v-show="detailsStatus === ''">
      <div class="tabs" :class="isTabes === item.id ? 'is-tabs':''" v-for="item in tabsList" :key="item.id" @click="switchTabs(item)">
        {{item.label}}
      </div>
    </div>
    <div class="content-box">
      <div v-show="detailsStatus === ''">
        <div class="filter-box">
          <el-button class="btn is-btn" style="width: 100px;" @click="add" v-if="isTabes === 1 && detailsStatus === ''">新增</el-button>
          <span>任务类型</span>
          <el-select class="long-select" v-model="filterInfo.type" filterable placeholder="请选择">
            <el-option
              v-for="item in issueTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <span>地区</span>
          <el-select
            v-model="provinceVal"
            filterable
            placeholder="省/直辖市/自治区"
            @change="selectProvince"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="cityVal"
            filterable
            placeholder="市"
            @change="selectCity"
          >
            <el-option
              v-for="item in cityInfo[provinceVal]"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-input class="ipt" v-model="filterInfo.keyVal" placeholder="搜索"></el-input>
          <el-button class="btn is-btn" @click="query">查询</el-button>
          <el-button class="btn" @click="initFilterr">重置</el-button>
          <div class="icon-box">
            <img class="img" :src="iconUrl1" @click="cutType(false)">
            <img class="img" :src="iconUrl2" @click="cutType(true)">
          </div>
        </div>
        <tablePage ref="tablePage" v-show="showStatus" @editTask="editTask"></tablePage>
        <cardPage ref="cardPage" v-show="!showStatus" @editTask="editTask"></cardPage>
      </div>
      <addPage v-if="detailsStatus == 'add' || detailsStatus == 'edit'" :info="detailsInfo"></addPage>
      <detailsPage v-if="detailsStatus == 'look'" :info="detailsInfo"></detailsPage>
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import { getAddress } from "@/FackApi/api/index.js";
import tablePage from "./modules/tablePage.vue"
import cardPage from "./modules/cardPage.vue"
import addPage from "./modules/addPage.vue"
import detailsPage from "./modules/detailsPage.vue"
export default {
  name: "highSeas",
  components: {
    tablePage,
    cardPage,
    addPage,
    detailsPage,
  },
  data() {
    return {
      tabsList: [
        {
          id: 1,
          label: "案源库",
        },
        {
          id: 2,
          label: "发布记录",
        },
        {
          id: 3,
          label: "领取记录",
        },
      ],
      isTabes: 1, // 当前订单类型 1：案源库 2：发布案件 3：领取案件
      filterInfo: {
        type: "",
        province: "",
        city: "",
        keyVal: "",
      },
      provinceList: [], // 省份集合
      cityInfo: {}, // 城市对象
      provinceVal: "", //省下拉框选中的数据
      cityVal: "", //市下拉框选中的数据
      provinceCityInfo: {}, // 选中的省市具体数据
      iconUrl1: require('@/assets/images/ywc/icon-card-2.png'),
      iconUrl2: require('@/assets/images/ywc/icon-list-1.png'),
      showStatus: true,
      detailsStatus: "", // 详情页面显示状态
      detailsInfo: {},
      issueTypeList: [ // 任务类型
        {value: "劳动法律法规政策咨询", label: "劳动法律法规政策咨询"},
        {value: "上门法律服务", label: "上门法律服务"},
        {value: "人事文件制度审核修订", label: "人事文件制度审核修订"},
        {value: "出具法律意见书或律师函", label: "出具法律意见书或律师函"},
        {value: "人力资源尽职调查", label: "人力资源尽职调查"},
        {value: "规章制度审查", label: "规章制度审查"},
        {value: "人力资源合规审计", label: "人力资源合规审计"},
        {value: "人力资源合规全流程", label: "人力资源合规全流程"},
        {value: "经济性裁员", label: "经济性裁员"},
        {value: "搬迁人员安置", label: "搬迁人员安置"},
        {value: "并购人员安置", label: "并购人员安置"},
        {value: "破产人员安置", label: "破产人员安置"},
        {value: "法律意见书", label: "法律意见书"},
        {value: "劳动争议", label: "劳动争议"},
        {value: "人事争议", label: "人事争议"},
        {value: "其他问题", label: "其他问题"},
      ],
    }
  },
  mounted() {
    core.index();
    this.init();
  },
  created() {
  },
  methods: {
    init() {
      this.getAddressInfo();
      this.query();
    },
    // 获取地区
    async getAddressInfo() {
      let res = await getAddress();
      if (res.code == 0) {
        res.items.map((item) => {
          this.provinceList.push({
            id: item.id,
            label: item.name,
            value: item.name,
          });
          this.cityInfo[item.name] = item.cities;
        });
      }
    },
    // 切换tabs
    switchTabs(info) {
      console.log(this.isTabes,'this.isTabes');
      if (this.isTabes === info.id) return;
      this.isTabes = info.id;
      this.query();
    },
    // 选择省
    selectProvince() {
      for (var i = 0; i < this.provinceList.length; i++) {
        let newItem = this.provinceList[i];
        if (newItem.label == this.provinceVal) {
          this.provinceCityInfo.provinceId = newItem.id;
          this.provinceCityInfo.province = newItem.value;
          this.provinceCityInfo.cityId = this.cityInfo[this.provinceVal][0].id;
          this.provinceCityInfo.city = this.cityInfo[this.provinceVal][0].name;
          this.cityVal = this.cityInfo[this.provinceVal][0].name;
          this.filterInfo.province = newItem.id;
          this.filterInfo.city = this.cityInfo[this.provinceVal][0].id;
          break;
        }
      }
    },
    // 选择市
    selectCity() {
      let newArr = this.cityInfo[this.provinceVal];
      for (var i = 0; i < newArr.length; i++) {
        let newItem = newArr[i];
        if (newItem.name == this.cityVal) {
          this.provinceCityInfo.cityId = newItem.id;
          this.provinceCityInfo.city = newItem.name;
          this.filterInfo.city = newItem.id;
          break;
        }
      }
    },
    // 查询
    query() {
      if (this.showStatus) {
        this.$nextTick(()=> {
          this.$refs.tablePage.init();
        })
      } else {
        this.$nextTick(()=> {
          this.$refs.cardPage.init();
        })
      }
    },
    // 重置
    initFilterr() {
      this.provinceVal = "";
      this.cityVal = "";
      this.filterInfo = {
        type: "",
        province: "",
        city: "",
        keyVal: "",
      }
      this.query();
    },
    // 新增
    add() {
      this.detailsInfo = {};
      this.detailsStatus = "add";
    },
    // 编辑
    editTask(row) {
      console.log(row);
      this.detailsInfo = row;
      this.detailsStatus = "edit";
    },
    // 查看
    look(row) {
      console.log(row);
      this.detailsInfo = row;
      this.detailsStatus = "look";
    },
    // 切换显示
    cutType(boole) {
      this.iconUrl1 = require(`@/assets/images/ywc/icon-card-${boole ? '2':'1'}.png`);
      this.iconUrl2 = require(`@/assets/images/ywc/icon-list-${boole ? '1':'2'}.png`);
      this.showStatus = boole;
      this.query();
    },
  }
}
</script>

<style lang="less" scoped>
  .highSeas {
    margin: 20px 15px 0;
    border-radius: 16px;
    padding: 20px 32px;
    box-shadow: 0px 0px 10px 0px rgb(152 172 190 / 20%);
    background-color: #fff;
    .tabs-box {
      display: flex;
      border-bottom: 1px solid #DEE2E6;
      .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 40px;
        font-size: 14px;
        font-weight: 600;
        color: #333;
        border-bottom: 4px solid #fff;
        cursor:pointer;
      }
      .is-tabs {
        color: #837AF3;
        border-bottom: 4px solid #ede4fd;
      }
    }
    /deep/ .content-box {
      margin-top: 30px;
      .filter-box {
        display: flex;
        align-items: center;
        position: relative;
        span {
          font-size: 14px;
          color: #333333;
          margin-right: 10px;
          white-space:nowrap;
        }
        .el-select {
          border-radius: 8px;
          margin-right: 10px;
          .el-input__inner {
            width: 160px;
            height: 40px;
            background: #EAF5FF;
          }
        }
        // .long-select {
        //   margin-right: 40px;
        //   .el-input__inner {
        //     width: 200px;
        //   }
        // }
        .ipt {
          width: 240px;
          margin: 0 40px 0 30px;
          .el-input__inner {
            height: 40px;
            background: #EAF5FF;
            border-radius: 8px;
          }
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 140px;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          background: #F1F2F1;
          border-radius: 25px;
          margin-right: 20px;
          span {
            color: #BBBBBB;
          }
        }
        .is-btn {
          background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
          span {
            color: #fff;
          }
        }
        .icon-box {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
          .img {
            width: 30px;
            height: 30px;
            margin-left: 10px;
            cursor:pointer;
          }
        }
      }
    }
  }
</style>

