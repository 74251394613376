// 引入request
import requests from '../../Utils/request'

// 业务类型
export const getAssignment = () => requests({
  url: `/api/assignment/type/list`,
  method: 'get',
})
// 律师添加订单
export const addOrder = (data) => requests({
  url: `/api/caserepository/order/add`,
  method: 'post',
  data,
})
// 获取待领取订单列表
export const getOrderList = (params) => requests({
  url: `/api/caserepository/order/paging/${params}`,
  method: 'get',
})
// 获取订单详情
export const getOrderDetail = (id) => requests({
  url: `/api/caserepository/order/detail/${id}`,
  method: 'get',
})
// 领取订单
export const receiveOrder = (data) => requests({
  url: `/api/caserepository/order/receive`,
  method: 'post',
  data,
})
// 删除订单
export const delOrder = (data) => requests({
  url: `/api/caserepository/order/delete`,
  method: 'delete',
  data,
})
// 编辑已发布订单订单
export const editOrder = (data) => requests({
  url: `/api/caserepository/order/edit`,
  method: 'post',
  data,
})
// 快速转换任务
export const taskTransition = (data) => requests({
  url: `/api/caserepository/order/transferToAssignment`,
  method: 'post',
  data,
})
// 获取已发布订单列表
export const getCreatedOrderList = (params) => requests({
  url: `/api/caserepository/order/created/paging/${params}`,
  method: 'get',
})
// 获取已领取订单列表
export const getReceivedOrderList = (params) => requests({
  url: `/api/caserepository/order/received/paging/${params}`,
  method: 'get',
})
