import { render, staticRenderFns } from "./detailsPage.vue?vue&type=template&id=0ec836dc&scoped=true&"
import script from "./detailsPage.vue?vue&type=script&lang=js&"
export * from "./detailsPage.vue?vue&type=script&lang=js&"
import style0 from "./detailsPage.vue?vue&type=style&index=0&id=0ec836dc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec836dc",
  null
  
)

export default component.exports