<template>
  <div class="affirm-task-dialog">
    <el-dialog
      title="确认信息"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="660px"
    >
      <div class="content-box">
      <p class="title-p">客户名称 【{{info.customerName}}】 在项目管理已存在，是否更新项目管理客户数据？</p>
        <el-radio-group class="radio-box" v-model="radio">
          <el-radio :label="1">更新</el-radio>
          <el-radio :label="2">新建</el-radio>
        </el-radio-group>
        <div class="ipt-box" v-if="radio == 2">
          <span>新建客户名称：</span>
          <span class="span2">客户名称不能重复，需设定新名称创建到项目管理</span>
          <el-input v-model="newName" placeholder="客户名称"></el-input>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="receive">确 认</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "affirmTaskDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      myInfo: null,
      radio: 1,
      newName: "",
    };
  },
  created() {
  },
  mounted() {
    console.log(this.info);
  },
  methods: {
    receive() {
      this.$parent.creation({num: this.radio, name: this.newName});
    },
    cancel() {
      this.$parent.showDialog2 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.affirm-task-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            white-space: nowrap;
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .content-box {
          margin: 0 0 10px 0;
          .title-p {
            font-size: 14px;
          }
          .radio-box {
            margin: 10px 0;
          }
          .ipt-box {
            span {
              font-size: 14px;
              color: #333;
            }
            .span2 {
              font-size: 12px;
              color: #bbb;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            width: 200px;
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
